import React from "react";
import BillVote from "./BillVote";

const MPCard = (props) => {
  let partyColor = "";

  switch (props.party) {
    case "Conservative":
      partyColor = "conservative";
      break;
    case "Liberal":
      partyColor = "liberal";
      break;
    case "NDP":
      partyColor = "ndp";
      break;
    case "Bloc Québécois":
      partyColor = "bloc";
      break;
    case "Green Party":
      partyColor = "green";
      break;
    case "PPC":
      partyColor = "ppc";
      break;
    default:
      partyColor = "default";
  }

  return (
    <div
      className={"border-2 rounded m-4 max-w-screen-md pty-border-" + partyColor}
    >
      <div className={"py-1 px-4 border pty-border-" + partyColor + " text-white font-bold pty-bg-" + partyColor}>
        <h5 className="text-lg capitalize">{props.party}</h5>
      </div>
      <div className="p-2 md:p-0 grid grid-cols-1 grid-rows-5 md:grid-cols-6 md:grid-rows-6">
        
        <div className="md:col-start-1 md:col-span-1 md:row-start-1 md:row-end-7 hidden md:block">
          {props.image_url === undefined && (
            <img
              src={process.env.PUBLIC_URL + "/defaultPortrait.jpg"}
              alt=""
              className="object-cover h-full"
            />
          )}
          {props.image_url !== undefined && (
            <img src={props.image_url} alt="" className="h-full object-cover" />
          )}
        </div>

        <div className="row-start-1 row-end-3 md:px-4 md:pt-2 md:col-start-2 md:col-end-5 md:row-start-1 md:row-end-5">
          <div className="">
            <p className="leading-none">
              <span className="pr-3 text-black font-sans">{props.riding}</span>
              <br />
              <span className="uppercase text-gray-700 font-medium text-sm">
                {props.riding_province}
              </span>
            </p>
          </div>
          <div className="my-6">
            <h3 className="text-2xl ">
              <span className="capitalize pr-2 text-gray-700">{props.f_name}</span>
              <span className="uppercase font-bold text-gray-700 text-3xl">
                {props.l_name}
              </span>
            </h3>
            <p className="text-sm text-gray-700 leading-none">
              {props.party_position}
            </p>
          </div>
        </div>

        {/* <div className="row-start-5 row-end-6 md:px-4 md:pb-2 md:col-start-2 md:col-end-5 md:row-start-5 md:row-end-7">
          <h5 className="font-medium capitalize">
            Recommendation based on Voting history:
          </h5>
          <div className="rounded bg-red-800 inline-block px-4 py-1 mt-1">
            <p className="text-4xl uppercase text-white font-black font-sans">NO</p>
          </div>
        </div> */}

        <div className="row-start-3 row-end-5 md:px-4 md:py-2 md:col-start-5 md:col-end-7 md:row-start-1 md:row-end-7">
          <h5 className="leading-none text-left font-bold">Voting History:</h5>
          <BillVote billname="Bill C-16" billvote={props.billc16} />
          <BillVote billname="Bill C-6" billvote={props.billc6} />
          <BillVote
            billname="Bill C-233"
            billvote={props.billc233}
            billgood={true}
          />
          <BillVote billname="Bill C-7" billvote={props.billc7} />
        </div>

      </div>
    </div>
  );
};

export default MPCard;
