import React, { useState, useEffect } from "react";
import CleaveInputField from "./components/CleaveInputField";
import MPCard from "./components/MPCard";
import VoteHistoryCard from "./components/VoteHistoryCard";

function App() {
  const [formState, setFormState] = useState({
    invalid: false,
    displayErrors: false,
    postalCode: "",
  });

  const [mpData, setMpData] = useState({
    mpRecordId: "",
    mpDataLoaded: false,
    noResults: false,
    mpData: "",
  });

  const [billData, setBillData] = useState({
    billDataLoaded: false,
    bills: {},
  });

  const siteURL = process.env.REACT_APP_URL;

  // useEffect(() => {
  //   const prevBillData = billData;

  //   if (billData.billDataLoaded === false) {
  //     fetch(
  //       "https://api.airtable.com/v0/app5QbWlpVjxYwbTx/Bills?api_key=" +
  //         process.env.REACT_APP_AIRTABLE_API
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         // console.log(data.records);

  //         setBillData({
  //           ...prevBillData,
  //           billDataLoaded: true,
  //           bills: data.records,
  //         });
  //       })
  //       .catch((error) => {
  //         // console.log(error);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    // console.log("MP record id:" + mpData.mpRecordId);

    if (mpData.mpRecordId !== "") {
      handleMPSearch();
    }
  }, [mpData.mpRecordId]);

  useEffect(() => {
    // console.log(mpData.mpData);
    const prevMpData = mpData;

    if (mpData.mpData !== "") {
      setMpData({
        ...prevMpData,
        mpDataLoaded: true,
        noResults: false,
      });
    }
  }, [mpData.mpData]);

  const handleMPSearch = () => {
    const prevmpDataState = mpData;

    fetch(
      "https://api.airtable.com/v0/app5QbWlpVjxYwbTx/MPs?view=APP_SEARCH&fields%5B%5D=Full_Name&fields%5B%5D=F_Name&fields%5B%5D=L_Name&fields%5B%5D=photo_url&fields%5B%5D=Party&fields%5B%5D=2021_Candidate%3F&fields%5B%5D=Riding_ProvTerr&fields%5B%5D=Bill_C7&fields%5B%5D=Bill_C6&fields%5B%5D=Bill_C233&fields%5B%5D=SHADOW&fields%5B%5D=Position&fields%5B%5D=Riding_Name&filterByFormula=RECORD_ID()%3D%22" +
        mpData.mpRecordId +
        "%22&maxRecords=1&pageSize=1&api_key=" +
        process.env.REACT_APP_AIRTABLE_API
    )
      .then((res) => res.json())
      .then((data) => {
        setMpData({
          ...prevmpDataState,
          mpData: data.records,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const prevFormState = formState;
    const prevmpDataState = mpData;

    // console.log("Postal code entered:" + formState.postalCode);

    if (mpData.mpDataLoaded === true) {
      clearMPData();
    }

    if (validatePostalCode(formState.postalCode)) {
      const formattedPostalCode = formState.postalCode.replace(/ /g, "+");
      fetch(
        "https://api.airtable.com/v0/app5QbWlpVjxYwbTx/Postal_Codes?fields%5B%5D=MP&filterByFormula=FIND(%22" +
          formattedPostalCode +
          "%22%2C%7BPostal_Codes%7D)&maxRecords=1&pageSize=1&api_key=" +
          process.env.REACT_APP_AIRTABLE_API
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("Result: " + data.records[0]);
          // console.log("Value: " + data.records[0].fields.MP[0]);
          if (data.records[0] === undefined) {
            // console.log("Did not find records.");
            setMpData({
              mpRecordId: "",
              mpDataLoaded: false,
              noResults: true,
              mpData: "",
            });
          } else {
            // console.log("found records");
            setMpData({
              ...prevmpDataState,
              mpRecordId: data.records[0].fields.MP[0],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setFormState({
        ...prevFormState,
        invalid: false,
        displayErrors: false,
      });
    } else {
      setFormState({
        ...prevFormState,
        invalid: true,
        displayErrors: true,
      });
      // console.log("Invalid postal code format.");
    }
  };

  const validatePostalCode = (pc) => {
    const regex = new RegExp(
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
    );
    return regex.test(pc);
  };

  const handlePostCode = (e) => {
    const inputValue = e.currentTarget.value;
    const prevState = formState;

    setFormState({
      ...prevState,
      postalCode: inputValue,
    });
  };

  const clearMPData = () => {
    setMpData({
      mpRecordId: "",
      mpDataLoaded: false,
      noResults: false,
      mpData: "",
    });
  };

  const clearFormState = () => {
    setFormState({
      invalid: false,
      displayErrors: false,
      postalCode: "",
    });
  };

  // This doesn't work due to cross-site origin policy
  // if (window.top.location.host !== siteURL) {
  //   return (<p>Access Denied</p>)
  // }

  // if (window.top.location.host === siteURL) {

  return (
    <div className="App container mx-auto max-w-screen-md">
      <div className="max-w-xs">
        <form
          className={formState.displayErrors ? "display-errors" : ""}
          noValidate
          onSubmit={handleSubmit}
        >
          <CleaveInputField
            label="Postal Code"
            id="postalcode"
            name="postalcode"
            required={true}
            options={{ blocks: [3, 3], delimiter: " ", uppercase: true }}
            value={formState.postalCode}
            onChange={handlePostCode}
            errorText="Invalid postal code format."
            helpText="eg. A1A 1A1"
          />
          <input type="submit" value="Search" className="btn btn-primary" />
        </form>
      </div>
      {mpData.mpDataLoaded === true && (
        <>
          <button
            onClick={() => {
              clearFormState();
              clearMPData();
            }}
            className="btn btn-gray my-4"
          >
            Clear
          </button>
          <MPCard
            party={mpData.mpData[0].fields.Party}
            f_name={mpData.mpData[0].fields.F_Name}
            l_name={mpData.mpData[0].fields.L_Name}
            party_position={mpData.mpData[0].fields.Position}
            image_url={mpData.mpData[0].fields.photo_url}
            riding={mpData.mpData[0].fields.Riding_Name[0]}
            riding_province={mpData.mpData[0].fields.Riding_ProvTerr[0]}
            billc7={mpData.mpData[0].fields.Bill_C7}
            billc6={mpData.mpData[0].fields.Bill_C6}
            billc233={mpData.mpData[0].fields.Bill_C233}
            billc16={mpData.mpData[0].fields.Bill_C16}
          />
        </>
      )}

      {mpData.noResults === true && (
        <p className="text-lg text-red-700 font-semibold mt-4">
          No results for that postal code.
        </p>
      )}
      {mpData.mpDataLoaded === false && (
        <MPCard
          party="Party"
          f_name=""
          l_name=""
          party_position=""
          image_url={undefined}
          riding="Riding"
          riding_province=""
          billc7="Nay"
          billc6="Nay"
          billc233="Yea"
          billc16="Nay"
        />
      )}
      {/* {mpData.mpDataLoaded === true && (
        <VoteHistoryCard info={billData.bills} />
      )} */}
    </div>
    
  );
  //}
}

export default App;
