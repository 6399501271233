import React from "react";

const ErrorLabel = (props) => {
  const { name, errorText, inner } = props;
  return (
    <label
      className={
        inner
          ? "error-text text-red-500 text-xs font-medium"
          : "error-text error-text-separate text-red-500 text-xs font-medium"
      }
      htmlFor={name}
    >
      {errorText}
    </label>
  );
};

export default ErrorLabel;