import React from "react";
import ErrorLabel from "./ErrorLabel";
import Cleave from 'cleave.js/react'

const CleaveInputField = (props) => {
  const {
    id,
    name,
    type,
    pattern,
    value,
    onChange,
    required,
    placeholder,
    options,
    label,
    helpText,
    errorText,
    wrapper,
  } = props;
  return (
    <div className="field-group space-y-1 my-3">
      <label
        className={
          required
            ? "text-field-label text-field-label-required font-normal text-base"
            : "text-field-label font-normal text-base"
        }
        htmlFor={id}
      >
        {label}
      </label>
      {wrapper !== true && (
        <Cleave
          className="text-field w-full block"
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          pattern={pattern}
          options={options}
        />
      )}

      {props.children}

      {helpText && (
        <span className="text-xs font-medium text-gray-700 pr-3">
          {helpText}
        </span>
      )}
      <ErrorLabel name={name} errorText={errorText} inner={true} />
    </div>
  );
};

export default CleaveInputField;