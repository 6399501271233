import React from "react";

const BillVote = (props) => {
  let bgcolor = "";

  if (props.billgood === true) {
    switch (props.billvote) {
      case "Yea" :
        bgcolor = "bg-green-800";
        break;
      case "Nay":
        bgcolor = "bg-red-800";
        break;
      default:
        bgcolor = "bg-gray-700";
    }
  } else {
    switch (props.billvote) {
      case "Yea":
        bgcolor = "bg-red-800";
        break;
      case "Nay":
        bgcolor = "bg-green-800";
        break;
      default:
        bgcolor = "bg-gray-700";
    }
  }

  return (
    <p className="my-2 ml-2 text-right font-sans">
      <span className="text-base mr-2">{props.billname}:</span>
      {props.billvote !== undefined && (

        <span
        className={
          "capitalize px-2 py-1 rounded text-white font-semibold " + bgcolor
        }
        >
        {props.billvote}
      </span>
        )}
        {props.billvote === undefined && (
          <span className="text-xs text-gray-700 font-normal">No Info</span>
        )}
    </p>
  );
};

export default BillVote;
